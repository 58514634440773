import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-intl"

import Img from "gatsby-image"
import { injectIntl, FormattedMessage } from "react-intl"

const Activities = ({ intl }) => {
  const rawData = useStaticQuery(graphql`
    {
      nl: allMarkdownRemark(
        filter: {
          frontmatter: { type: { eq: "activity" }, langKey: { eq: "nl" } }
        }
        sort: { fields: frontmatter___weight }
      ) {
        edges {
          node {
            id
            html: excerpt

            frontmatter {
              title
              intro
              id
              image {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      fr: allMarkdownRemark(
        filter: {
          frontmatter: { type: { eq: "activity" }, langKey: { eq: "fr" } }
        }
        sort: { fields: frontmatter___weight }
      ) {
        edges {
          node {
            id
            html: excerpt

            frontmatter {
              title
              intro
              id
              image {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = rawData[intl.locale]

  return (
    <div className="c-container">
      <h2 className="c-section-title c-section-title--dark">
        <FormattedMessage id="our_services" />
      </h2>
      <div className="o-grid o-grid--justify-center c-activity-grid">
        {data.edges.map(({ node }) => {
          const {
            frontmatter: {
              title,
              intro,
              id: html_id,
              image: {
                childImageSharp: { fluid },
              },
            },

            id,
          } = node
          return (
            <div
              key={id}
              className="o-grid__item u-1/3@desktop c-activity-wrapper"
            >
              <div className="c-activity">
                <Link to={`/activiteiten#${html_id}`} />
                <div className="c-activity__image">
                  <Img fluid={fluid} />
                </div>
                <div className="c-activity__content">
                  <h2 className="c-activity__title">{title}</h2>
                  <div
                    className="c-activity__body"
                    dangerouslySetInnerHTML={{
                      __html: intro,
                    }}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default injectIntl(Activities)
